import {useEffect} from 'react'
import {Link, useLocation} from 'react-router-dom'
import routerItems from '../../app/routing/routerItems'
import PointLeaderFooter from './components/PointLeaderFooter'
import {ScrollTop} from './components/ScrollTop'
import {WithChildrenProps} from '../../types'
import appConfig from '../../app/constants/appConfig'

const DefaultLayout: React.FC<WithChildrenProps> = ({children}) => {
  const pathname = useLocation().pathname
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      <div className='d-flex justify-content-between py-3 px-5 border-bottom w-100'>
        <Link to={routerItems.HOME}>
          <img alt='Logo' src={appConfig.fullLogoDarkHorizontalPath} className='h-45px' />
        </Link>

        <Link
          to={
            pathname === routerItems.AUTH_LOGIN ? routerItems.AUTH_REGISTER : routerItems.AUTH_LOGIN
          }
          className='btn btn-primary'
        >
          {pathname === routerItems.AUTH_LOGIN ? 'Register' : 'Login'}
        </Link>
      </div>
      <div className='container py-5'>{children}</div>
      <div className='container py-4 d-flex flex-lg-column'>
        <PointLeaderFooter />
      </div>
      <ScrollTop />
    </div>
  )
}

export default DefaultLayout
