import appConfig from '../../../../app/constants/appConfig'

export function FallbackView() {
  return (
    <div className='d-flex justify-content-center align-items-center p-5 gap-5'>
      <img src={appConfig.logoPath} alt='Start logo' width={70} />
      <span>Loading ...</span>
    </div>
  )
}
