const appConfig = {
  appUrl: process.env.REACT_APP_APP_URL || 'https://pldr-exp.app',
  appShortName: process.env.REACT_APP_APP_SHORT_NAME || 'PointLeader',
  appDescription: process.env.REACT_APP_APP_DESCRIPTION || 'Employee Experience Portal',
  appFullName: process.env.REACT_APP_APP_FULL_NAME || 'PointLeader Predictive Analytics',
  logoPath: process.env.REACT_APP_LOGO_PATH || '/media/logos/favicon.ico',
  fullLogoVerticalPath: process.env.REACT_APP_FULL_LOGO_V_PATH || '/media/logos/full-logo.png',
  fullLogoHorizontalPath: process.env.REACT_APP_FULL_LOGO_H_PATH || '/media/logos/full-logo.png',
  fullLogoDarkHorizontalPath:
    process.env.REACT_APP_FULL_LOGO_DARK_H_PATH || '/media/logos/full-logo.png',
  favIconPath: process.env.REACT_APP_FAV_ICON_PATH || '/media/logos/favicon.ico',
}

export default appConfig
